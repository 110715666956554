<template>
  <div class="content md-mt-0 md-pt-0">
    <div class="md-layout">
      <stats-card>
        <template slot="header">
          <div
            class="card-icon md-elevation-5"
            style="margin-right: auto;"
            :style="
              `background: linear-gradient(60deg, rgba(153, 153, 153, 0.5) 1%, ${dataSet.color} 95%)`
            "
          >
            <md-icon>map</md-icon>
          </div>
          <p class="category">Total AIH {{ dataSet.total | locateNumber }}</p>

          <h3 class="title">
            {{ dataSet.year }}
          </h3>
        </template>
        <template slot="content">
          <md-app md-mode="reveal">
            <md-app-toolbar class="md-primary md-dense" md-elevation="5">
              <div class="md-toolbar-section-start">
                <h4 class="md-mt-0 md-mb-0">
                  Mapa de atendimento de residentes e não residentes
                </h4>
              </div>
              <div class="md-toolbar-section-end">
                <md-button class="md-raised md-white" @click="() => {}">
                  Download PNG
                </md-button>
                <a id="image-download" download="map.png"></a>

                <md-button
                  class="md-raised"
                  @click="setVisible"
                  v-if="!menuVisible"
                >
                  <md-icon>filter_alt</md-icon>
                </md-button>
              </div>
            </md-app-toolbar>

            <md-app-drawer :md-active.sync="menuVisible" md-right>
              <md-toolbar class="md-primary" md-elevation="5">
                <div class="md-toolbar-section-start">
                  <md-button
                    class="md-icon-button md-dense"
                    @click="setVisible"
                  >
                    <md-icon>keyboard_arrow_right</md-icon>
                  </md-button>
                </div>
                <span>Filtros</span>
              </md-toolbar>
              <filter-cid-card @update="setCids"></filter-cid-card>
              <md-list class="md-dense">
                <md-list-item style="color: #CC6532;">
                  <md-switch v-model="cluster" />
                  <md-icon style="color: #CC6532;">bubble_chart</md-icon>
                  <span class="md-list-item-text">Cluster de origem</span>
                </md-list-item>

                <md-list-item style="color: #CC3246;">
                  <md-switch v-model="arrow" />
                  <md-icon style="color: #CC3246;">arrow_forward</md-icon>
                  <span class="md-list-item-text">Setas de origem</span>
                </md-list-item>

                <md-list-item>
                  <md-icon style="color: #03a9f4;">house</md-icon>
                  <span style="color: #03a9f4;" class="md-list-item-text"
                    >Redidente</span
                  >
                </md-list-item>

                <md-list-item>
                  <md-icon style="color: #FF4560;">route</md-icon>
                  <span style="color: #FF4560;" class="md-list-item-text">
                    Não residente
                  </span>
                </md-list-item>
              </md-list>
            </md-app-drawer>
            <md-app-content>
              <vl-map
                :load-tiles-while-animating="true"
                :load-tiles-while-interacting="true"
                data-projection="EPSG:4326"
                style="height: 600px"
                ref="map"
              >
                <vl-view
                  :zoom.sync="zoom"
                  :center.sync="center"
                  :rotation.sync="rotation"
                ></vl-view>

                <vl-layer-tile id="osm">
                  <vl-source-osm></vl-source-osm>
                </vl-layer-tile>

                <vl-layer-vector>
                  <vl-source-vector
                    ref="layerRegions"
                    :features.sync="regionsFeatures"
                  >
                  </vl-source-vector>
                  <vl-style-box>
                    <vl-style-stroke
                      color="rgba(255,255,255,0.5)"
                      :width="1"
                    ></vl-style-stroke>
                  </vl-style-box>
                </vl-layer-vector>

                <vl-layer-vector>
                  <vl-source-vector
                    ref="featureOverlay"
                    :features.sync="featureOverlay"
                  >
                  </vl-source-vector>
                  <vl-style-box>
                    <vl-style-stroke
                      color="rgba(255,255,255,0.5)"
                      :width="3"
                    ></vl-style-stroke>
                    <vl-style-fill
                      color="rgba(255,255,255,0.3)"
                    ></vl-style-fill>
                  </vl-style-box>
                </vl-layer-vector>

                <vl-layer-vector :visible="arrow">
                  <vl-source-vector
                    ref="arrowSource"
                    :features.sync="arrowOrigins"
                  >
                  </vl-source-vector>
                  <vl-style-func :factory="arrowStyleFunc"></vl-style-func>
                </vl-layer-vector>

                <vl-layer-vector :visible="cluster">
                  <vl-source-cluster :distance="50">
                    <vl-source-vector
                      ref="clusterSourceDestinations"
                      :features.sync="markersClustersOrigins"
                    >
                      <vl-style-func
                        :factory="clusterStyleDestinyFunc"
                      ></vl-style-func>
                    </vl-source-vector>
                  </vl-source-cluster>
                </vl-layer-vector>

                <vl-layer-vector>
                  <vl-source-cluster :distance="50">
                    <vl-source-vector ref="clusterSourceOrigins">
                      <vl-style-func
                        :factory="clusterStyleOriginFunc"
                      ></vl-style-func>
                    </vl-source-vector>
                  </vl-source-cluster>
                </vl-layer-vector>

                <vl-overlay
                  v-for="resident in residentsOverlay"
                  :key="resident.munic_mov"
                  :id="resident.munic_mov"
                  class="feature-popup"
                  :position="resident.position"
                  :auto-pan="true"
                  :auto-pan-animation="{ duration: 300 }"
                  v-show="displayOverlay"
                >
                  <template>
                    <PieChartOverlay :feature="resident"></PieChartOverlay>
                  </template>
                </vl-overlay>

                <!-- interactions -->
                <vl-interaction-select :features.sync="selectedFeatures">
                  <template slot-scope="selection">
                    <!--
                    <vl-layer-vector>
                      <vl-feature
                        v-for="item in selection.features"
                        :key="item.id"
                        :id="`polygon-overlay-${item.id}`"
                        :properties="item.properties"
                      >
                        <template slot-scope="feature">
                          <vl-geom-polygon
                            :coordinates="item.geometry.coordinates"
                          >
                            {{ feature }}
                          </vl-geom-polygon>
                          <vl-style-box>
                            <vl-style-stroke
                              color="rgba(255,255,255,0.9)"
                              :width="3"
                            ></vl-style-stroke>
                            <vl-style-fill
                              color="rgba(255,255,255,0.9)"
                            ></vl-style-fill>
                          </vl-style-box>
                        </template>
                      </vl-feature>
                    </vl-layer-vector>
                    -->
                    <!-- select styles -->
                    <!--// select styles -->

                    <!-- selected feature popup -->
                    <vl-overlay
                      v-for="feature in selection.features"
                      :key="feature.id"
                      :id="feature.id"
                      class="feature-popup"
                      :position="pointOnSurface(feature.geometry)"
                      :auto-pan="true"
                      :auto-pan-animation="{ duration: 300 }"
                    >
                      <template>
                        <OverlayCard
                          :feature="feature"
                          @click="
                            selectedFeatures = selectedFeatures.filter(
                              f => f.id !== feature.id
                            )
                          "
                        ></OverlayCard>
                      </template>
                    </vl-overlay>
                    <!--// selected popup -->
                  </template>
                </vl-interaction-select>
              </vl-map>
            </md-app-content>
          </md-app>
        </template>
        <template slot="footer">
          <div class="stats">
            <md-icon>today</md-icon>
            Atualizado em: {{ dataSet.updated_at | formatDate }}
          </div>
        </template>
      </stats-card>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueLayers from "vuelayers";
import { StatsCard, FilterCidCard } from "@/components";
import { createStyle, findPointOnSurface } from "vuelayers/lib/ol-ext";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { fromLonLat } from "ol/proj";
import FlowLine from "ol-ext/style/FlowLine";
import { Fill, Stroke, Style, Text } from "ol/style";
import { kebabCase } from "lodash";
import OverlayCard from "./OverlayCard";
import PieChartOverlay from "./PieChartOverlay";

Vue.use(VueLayers, {
  dataProjection: "EPSG:4326"
});
export default {
  components: {
    StatsCard,
    OverlayCard,
    PieChartOverlay,
    FilterCidCard
  },
  props: {
    marker_health_unit: {
      type: String,
      default: require("@/assets/img/health_unit.png")
    }
  },
  computed: {
    loggedIn: function() {
      return this.$store.state.auth.status.loggedIn;
    },
    user: function() {
      return this.$store.state.auth.user;
    }
  },
  data() {
    return {
      filterItem: null,
      selectedCids: [],
      selectedChaptersCids: [],
      selectedGroupsCids: [],
      displayOverlay: true,
      cluster: true,
      arrow: false,
      region: { name: "" },
      source: this.$route.params.source,
      type: this.$route.params.type,
      id: this.$route.params.id,
      location_type: this.$route.params.location_type
        ? this.$route.params.location_type
        : null,
      location_id: this.$route.params.location_id
        ? this.$route.params.location_id
        : null,
      dataSet: { year: null, alias: null },
      zoom: 7.1,
      center: [-43.0691055, -6.8420932],
      rotation: 0,
      geolocPosition: undefined,
      menuVisible: false,
      markersClustersDestinations: [],
      markersClustersOrigins: [],
      arrowOrigins: [],
      origins: [],
      regionsFeatures: [],
      selectedFeatures: [],
      featureOverlay: [],
      residentsOverlay: []
    };
  },
  watch: {
    arrow: function(val) {
      if (val == false) {
        this.cluster = true;
      }
    },
    cluster: function(val) {
      if (val == false) {
        this.arrow = true;
      }
    }
  },
  async created() {
    //await this.getDataSet();
  },
  async beforeMounted() {},
  async mounted() {
    await this.getLayerMap();
    //await this.getDataMapDestiny();
    //await this.getDataMapOrigin();
    await this.setEventMap();
    await this.getDataMapResident();
  },
  methods: {
    setCids(selectedCids) {
      if (selectedCids.type == "cids") {
        this.selectedCids = [...selectedCids.data];
        this.getDataMapResidentUpdate();
      } else if (selectedCids.type == "chaptersCids") {
        this.selectedChaptersCids = [...selectedCids.data];
        this.getDataMapResidentUpdate();
      } else if (selectedCids.type == "groupsCids") {
        this.selectedGroupsCids = [...selectedCids.data];
        this.getDataMapResidentUpdate();
      }
    },
    setVisible() {
      console.log(this.menuVisible);
      this.menuVisible = !this.menuVisible;
    },
    setFilters(params) {
      if (this.selectedCids.length > 0) {
        let filters_cid = [];
        for (const cid of this.selectedCids) {
          filters_cid.push(cid.code);
        }
        params.column_filters = "diag_princ";
        params.term_filters = filters_cid;
      }

      if (this.selectedChaptersCids.length > 0) {
        if (params.column_filter_or == undefined) {
          params.column_filter_or = [];
        }
        if (params.term_filter_or_range == undefined) {
          params.term_filter_or_range = [];
        }

        for (const chapterCid of this.selectedChaptersCids) {
          params.column_filter_or.push("diag_princ");
          params.term_filter_or_range.push([
            chapterCid.starting_code,
            chapterCid.final_code
          ]);
        }
      }

      if (this.selectedGroupsCids.length > 0) {
        if (params.column_filter_or == undefined) {
          params.column_filter_or = [];
        }
        if (params.term_filter_or_range == undefined) {
          params.term_filter_or_range = [];
        }
        for (const groupCid of this.selectedGroupsCids) {
          params.column_filter_or.push("diag_princ");
          params.term_filter_or_range.push([
            groupCid.starting_code,
            groupCid.final_code
          ]);
        }
      }

      return params;
    },
    pointOnSurface: findPointOnSurface,
    geometryTypeToCmpName(type) {
      return "vl-geom-" + kebabCase(type);
    },
    selectFilter(feature) {
      return ["position-feature"].indexOf(feature.getId()) === -1;
    },
    async getDataSet() {
      let result = await Vue.$http.get(`v1/dataset/${this.id}/`);
      this.dataSet = result.data;
    },
    async getLayerMap() {
      let result = await Vue.$http.get(`v1/dataset/${this.id}/`);
      this.dataSet = result.data;
      try {
        let result = await Vue.$http.get(
          `v1/city/${this.dataSet.source}/${this.dataSet.name}/${this.dataSet.year}?type=map`
        );
        let regions = result.data;
        await regions.forEach(region => {
          this.regionsFeatures.push({
            type: "Feature",
            id: region.id,
            ibge_id_short: region.ibge_id_short,
            geometry: JSON.parse(region.geoJson),
            properties: {
              name: region.name,
              ibge_id_short: region.ibge_id_short,
              health_units: []
            }
          });
        });

        if (
          this.$refs.layerRegions !== undefined &&
          this.$refs.layerRegions.$source !== undefined
        ) {
          await this.$refs.layerRegions.$source.forEachFeature(feature => {
            feature.setStyle(
              new Style({
                stroke: new Stroke({ color: "#cccccc", width: 1 }),
                fill: new Fill({
                  color: "rgba(217,217,217, 0.5)"
                })
              })
            );
            feature.getStyle().setText(
              new Text({
                text: feature.getProperties().name,
                font: "12px Calibri,sans-serif",
                fill: new Fill({ color: "#000" }),
                stroke: new Stroke({ color: "#fff", width: 4 })
              })
            );
          });
        }
      } catch (e) {
        await this.$store.dispatch("alerts/error", e);
      }
    },
    async setEventMap() {
      this.$refs.map.$map.on("pointermove", evt => {
        this.$refs.layerRegions.$sourceContainer.$layersContainer.forEachFeatureAtPixel(
          evt.pixel,
          f => {
            let newFeature = this.regionsFeatures.find(feature => {
              return feature.id == f.getId();
            });
            if (newFeature !== undefined) {
              this.featureOverlay = [];
              this.featureOverlay.push(newFeature);
            }
            return true;
          }
        );
      });

      this.$refs.map.$map.on("dblclick", evt => {
        this.displayOverlay = true;
        this.arrowOrigins = [];
        this.markersClustersOrigins = [];
        this.$refs.layerRegions.$sourceContainer.$layersContainer.forEachFeatureAtPixel(
          evt.pixel,
          f => {
            let selectFeature = this.regionsFeatures.find(feature => {
              return feature.id == f.getId();
            });
            if (selectFeature !== undefined) {
              this.displayOverlay = false;
              this.getDataMapOrigin(selectFeature);
            }
            return true;
          }
        );
      });
    },
    async getDataMapDestiny() {
      let destiny;
      if (this.location_type == null) {
        destiny = await Vue.$http.get(
          `v1/dataset/consume/map/datasus/sih/${this.id}/`,
          {
            params: {
              per: "n_aih",
              ratings: ["n_aih"],
              operations: ["count"],
              columns: ["munic_mov"],
              scope: "destiny",
              per_page: 2000,
              page: 1
            }
          }
        );
      } else {
        destiny = await Vue.$http.get(
          `v1/dataset/consume/map/datasus/sih/${this.id}/`,
          {
            params: {
              per: "n_aih",
              ratings: ["n_aih"],
              operations: ["count"],
              columns: ["munic_mov"],
              scope: "destiny",
              location_type: this.location_type,
              location_id: this.location_id,
              per_page: 2000,
              page: 1
            }
          }
        );
      }

      let destinations = destiny.data.data;

      for (let i = 0; i < destinations.length; i++) {
        const coordinate = [
          parseFloat(destinations[i].destiny_longitude),
          parseFloat(destinations[i].destiny_latitude)
        ];
        this.markersClustersDestinations.push(
          new Feature({
            type: "Feature",
            id: "item-" + i.toString(),
            geometry: new Point(fromLonLat(coordinate)),
            name: destinations[i].destiny_name,
            properties: {
              count: destinations[i].count_n_aih
            }
          })
        );
      }

      this.$refs.clusterSourceDestinations.$source.addFeatures(
        this.markersClustersDestinations
      );
    },
    async getDataMapOrigin(feature) {
      let origin;

      this.markersClustersOrigins = [];

      let params = {
        per: "n_aih",
        ratings: ["n_aih"],
        operations: ["count"],
        column_filter: ["munic_mov"],
        term_filter: [feature.properties.ibge_id_short],
        columns: ["munic_res"],
        scope: "origin",
        per_page: 2000,
        page: 1
      };

      if (this.location_type == null) {
        if (this.selectedCids.length > 0) {
          let term_filters = [];
          for (const cid of this.selectedCids) {
            term_filters.push(cid.code);
          }
          params.column_filters = "diag_princ";
          params.term_filters = term_filters;
        }
      } else {
        params.location_type = this.location_type;
        params.location_id = this.location_id;
        if (this.selectedCids.length > 0) {
          let term_filters = [];
          for (const cid of this.selectedCids) {
            term_filters.push(cid.code);
          }
          params.column_filters = "diag_princ";
          params.term_filters = term_filters;
        }
      }

      origin = await Vue.$http.get(
        `v1/dataset/consume/map/datasus/sih/${this.id}/`,
        {
          params: params
        }
      );

      let origins = origin.data.data;

      for (let i = 0; i < origins.length; i++) {
        const coordinate = [
          parseFloat(origins[i].origin_longitude),
          parseFloat(origins[i].origin_latitude)
        ];

        this.markersClustersOrigins.push({
          type: "Feature",
          id: i.toString(),
          geometry: {
            type: "Point",
            coordinates: coordinate
          },
          properties: {
            name: origins[i].origin_name,
            count: origins[i].count_n_aih
          }
        });
      }

      this.setArrow(feature, origins);
    },
    async getDataMapResident() {
      let resident;
      let params = {
        per: "resident",
        per_page: 2000,
        page: 1
      };

      if (this.location_type == null) {
        if (this.selectedCids.length > 0) {
          let term_filters = [];
          for (const cid of this.selectedCids) {
            term_filters.push(cid.code);
          }
          params.column_filters = "diag_princ";
          params.term_filters = term_filters;
        }
      } else {
        params.location_type = this.location_type;
        params.location_id = this.location_id;

        if (this.selectedCids.length > 0) {
          let term_filters = [];
          for (const cid of this.selectedCids) {
            term_filters.push(cid.code);
          }
          params.column_filters = "diag_princ";
          params.term_filters = term_filters;
        }
      }

      resident = await Vue.$http.get(
        `v1/dataset/consume/map/datasus/sih/${this.id}/`,
        {
          params: params
        }
      );

      let residents = resident.data.data;
      this.residentsOverlay = this.residentsOverlay.slice(
        0,
        this.residentsOverlay.length - 1
      );

      residents.forEach(resident => {
        //const user = users.find((user) => user.id === 2)
        let region = this.regionsFeatures.find(
          regionFeature =>
            resident.munic_mov == regionFeature.properties.ibge_id_short
        );

        resident.position = this.pointOnSurface(region.geometry);
        resident.name = region.properties.name;
        this.residentsOverlay.push(resident);
      });
    },
    async getDataMapResidentUpdate() {
      let resident;
      let params = {
        per: "resident",
        per_page: 2000,
        page: 1
      };

      params = this.setFilters(params);

      resident = await Vue.$http.get(
        `v1/dataset/consume/map/datasus/sih/${this.id}/`,
        {
          params: params
        }
      );

      let residents = resident.data.data;
      //this.residentsOverlay = [];

      this.residentsOverlay.forEach(munic => {
        munic.resident = 0;
        munic.non_resident = 0;
        residents.forEach(resident => {
          if (munic.munic_mov == resident.munic_mov) {
            munic.resident = resident.resident;
            munic.non_resident = resident.non_resident;
          }
        });
      });

      /*
      residents.forEach(resident => {
        //const user = users.find((user) => user.id === 2)
        let region = this.regionsFeatures.find(
          regionFeature =>
            resident.munic_mov == regionFeature.properties.ibge_id_short
        );

        resident.position = this.pointOnSurface(region.geometry);
        resident.name = region.properties.name;
        this.residentsOverlay.push(resident);
      });
      */
    },
    clusterStyleDestinyFunc() {
      return function __clusterStyleFunc(feature) {
        const features = feature.get("features");

        let total = 0;
        for (let i = 0; i < features.length; i++) {
          total += features[i].values_.count;
        }

        let radius = 20;

        radius += total * 0.009;

        let style = createStyle({
          imageRadius: radius,
          strokeColor: "#fff",
          fillColor: "rgba(204,101,50,0.9)",
          text: total.toString(),
          textFillColor: "#fff"
        });

        return [style];
      };
    },
    clusterStyleOriginFunc() {
      const cache = {};
      return function __clusterStyleFunc(feature) {
        const size = feature.get("features").length;
        const features = feature.get("features");

        let total = 0;
        for (let i = 0; i < features.length; i++) {
          total += features[i].values_.properties.count;
        }
        let style = cache[size];
        let radius = 20;

        radius += total * 0.009;

        if (!style) {
          style = createStyle({
            imageRadius: radius,
            strokeColor: "#fff",
            fillColor: "rgba(204,101,50,0.5)",
            text: total.toString(),
            textFillColor: "#fff"
          });

          cache[size] = style;
        }
        return [style];
      };
    },
    setArrow(feature, origins) {
      let coordinateDestiny = this.pointOnSurface(feature.geometry);
      this.arrowOrigin = [];
      for (let i = 0; i < origins.length; i++) {
        let coordinateOrigin = [
          parseFloat(origins[i].origin_longitude),
          parseFloat(origins[i].origin_latitude)
        ];
        this.arrowOrigins.push({
          type: "Feature",
          id: i.toString(),
          geometry: {
            type: "LineString",
            coordinates: [coordinateOrigin, coordinateDestiny]
          },
          name: `${origins[i].origin_name}-${feature.properties.name}`,
          properties: {
            name: `${origins[i].origin_name}-${feature.properties.name}`
          }
        });
      }
    },
    arrowStyleFunc() {
      let __arrowStyleFunc = feature => {
        let defaultStyle = new Style({
          stroke: new Stroke({ color: [255, 255, 255, 0.1], width: 2.5 })
        });
        let flowStyle = new FlowLine({
          color: "rgba(204,101,50,0.9)",
          color2: "rgb(204,50,75)",
          width: 6,
          width2: 1,
          offset0: 0.5,
          arrow: 0.5,
          geometry: feature.getGeometry(),
          zIndex: -feature.getGeometry().getLastCoordinate()[1]
        });
        return [defaultStyle, flowStyle];
      };

      return __arrowStyleFunc;
    }
  }
};
</script>

<style scoped lang="scss">
.md-drawer {
  width: 600px;
  //max-width: calc(100vw - 125px);
}
.filters {
  max-width: 230px;
  min-width: 200px;
  max-height: 200px;
  overflow: auto;
}

.md-toolbar {
  min-height: 64px !important;
}

.filter-item {
}
</style>
