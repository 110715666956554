<template>
  <div
    v-if="feature.resident > 0 || feature.non_resident > 0"
    :id="`chart-${feature.munic_mov}`"
    class="chart-transparent"
  >
    {{ feature.name }}

    <apexchart
      type="pie"
      :options="chartOptions"
      :series="series"
      ref="pieChart"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts
  },
  props: {
    feature: Object
  },
  mounted() {},
  watch: {
    "feature.resident": function() {
      if (this.$refs.pieChart !== undefined) {
        this.$refs.pieChart.updateSeries(
          [this.feature.resident, this.feature.non_resident],
          false,
          true
        );
      }
    }
  },
  data() {
    return {
      series: [this.feature.resident, this.feature.non_resident],
      chartOptions: {
        chart: {
          width: 100,
          type: "pie"
        },
        labels: ["Residentes", "Não residentes"],
        legend: {
          show: false
        },
        colors: ["#03a9f4", "#FF4560"],
        dataLabels: {
          enabled: true,
          style: {
            colors: ["rgab(255,255,255,0.5)"]
          }
        }
      }
    };
  }
};
</script>

<style scoped lang="scss">
.chart-transparent {
  width: 100px;
  text-align: center;
  // layout
  position: relative;
  left: -15px;
  top: -15px;
  max-width: 30em;

  // looks
  background-color: rgba(255, 255, 255, 0.7);
  padding: 0;
  font-size: 1.25em;
  border-radius: 1rem;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.3),
    0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);
}

.chart-transparent::before {
  // layout
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  bottom: 100%;
  left: 0.7em; // offset should move with padding of parent
  border: 0.75rem solid transparent;
  border-top: none;

  // looks
  border-bottom-color: rgba(255, 255, 255, 0.7);
  filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
}
</style>
